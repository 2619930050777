let switchers = document.querySelectorAll(".switcher");

if (localStorage.getItem("theme") === "dark") {
    document.documentElement.classList.add("dark");
} else if (localStorage.getItem("theme") === "light") {
    document.documentElement.classList.remove("dark");
} else if (localStorage.getItem("theme") === "system") {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
}

switchers.forEach((switcher) => {
    switcher.addEventListener("click", function () {
        if (localStorage.getItem("theme")) {
            if (localStorage.getItem("theme") === "light") {
                document.documentElement.classList.add("dark");
                localStorage.setItem("theme", "dark");
            } else if (localStorage.getItem("theme") === "dark") {
                document.documentElement.classList.remove("dark");
                localStorage.setItem("theme", "light");
            } else if (localStorage.getItem("theme") === "system") {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    document.documentElement.classList.remove("dark");
                    localStorage.setItem("theme", "light");
                } else {
                    document.documentElement.classList.add("dark");
                    localStorage.setItem("theme", "dark");
                }
            }
        } else {
            if (document.documentElement.classList.contains("dark")) {
                document.documentElement.classList.remove("dark");
                localStorage.setItem("theme", "light");
            } else {
                document.documentElement.classList.add("dark");
                localStorage.setItem("theme", "dark");
            }
        }
    });
});
